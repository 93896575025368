<div class="row ps-4 pe-4">
  <div class="col-12 border-bottom p-4">
    <span>
      <icon
        [(icon)]="defaultIcon"
        (iconChange)="iconUpdate($event)"
        [pickable]="dataOfImport.isNew"
        color="naviblue-light"
        size="xxlarge"
        style="margin-inline-end: 8px"
      ></icon>
      <span class="fw-bold m-0 p-0">{{ dataOfImport.sheetName }}</span>
    </span>
  </div>
  <div class="col-4 p-4">
    <div class="row">
      <div class="col-12 ps-5 p-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            (change)="includeHeaderFunc($event)"
            [checked]="includedHeader"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">{{'INCLUDE_HEADER' | translate }}</label>
        </div>
      </div>
      <div class="col-12 ps-5 p-3">
        <table class="w-100">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th class="text-center">{{'Primary'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of importFileManage; let i = index">
              <td>
                <div class="form-check mt-2">
                  <input
                  [disabled]="item.isPrimary"
                    class="form-check-input"
                    type="checkbox"
                    value="{{ item.name }}"
                    id="id{{ item.index }}"
                    checked
                    (change)="addOrRemoveColumn($event, item,i)"
                  />
                  <label class="form-check-label" for="id{{ item.index   }}">{{ item.name }}</label>
                </div>
              </td>
              <td>

                <select
                  *ngIf="!item.isPrimary && !item.isHide"
                  class="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  [(ngModel)]="item.dataTypeId"
                >
                  <option selected disabled value="{{ 0 }}">Open this select menu</option>
                  <option *ngFor="let dataType of dataTypesList" value="{{ dataType.id }}">
                    {{ dataType.name }}
                  </option>
                </select>
              </td>
              <td>
                <div class="form-check mt-2">
                  <input
                    *ngIf="!item.isHide"
                    class="form-check-input m-auto"
                    type="radio"
                    name="flexRadioDefault"
                    [value]="item.index"
                    [(ngModel)]="selectedRadio"
                    (change)="setPrimary($event, item)"
                    id="flexRadioDefault{{ item.name }}"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-8 p-4 overflow-scroll">
    <table class="preview-table border-0">
      <thead>
        <tr>
          <th *ngFor="let head of finalAttributes">{{ head.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let body of bodyOfTable.slice(0, 10)">
          <td *ngFor="let item of body">{{ item }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 p-4 border-top">
    <button class="btn btn-success float-end btn-sm" (click)="sendFile()" [disabled]="!isDone">
      {{ 'finish'|translate }}
    </button>
  </div>
</div>
