<div class="p-4">
  <div class="pt-4 pb-4">
    <p class="fw-bold m-0 p-0 ">{{ 'IMPORT_CSV_FILE' | translate }}</p>
  </div>
  <p-divider/>

  <div class="pt-4 pb-4">
    @if (isNew) {
      <div class="form-label">{{ 'SHEET_NAME' | translate }}</div>
      <input type="text" class="form-control mb-2" [(ngModel)]="sheetName">
    }


    <div class="cursor-pointer text-align-center align-items-center justify-content-center"
         (click)="filePicker.click()">
      @if (importFile) {
        <span>{{ importFile.name }}</span>
      } @else {
        {{ 'CHOOSE_FILE' | translate }}
      }
      <input (change)="onFileChange($event)" class="d-none mb-3" #filePicker type="file"
             id="formFile"
             accept=".csv">
      <icon icon="bi-cloud-arrow-up-fill" size="large"/>
    </div>
  </div>
  <p-divider/>

  <div class="pt-4 pb-4 d-flex flex-row justify-content-between">

    <loadable-button type="neutral"
                     (click)="closeModal()">{{ 'cancel' | translate }}
    </loadable-button>
    <loadable-button type="submit" (click)="nextBtn()"
                     [disabled]="!importFile || (!sheetName && isNew)||(!isNew && !sheet)">{{ 'NEXT' | translate }}
    </loadable-button>
  </div>
</div>
