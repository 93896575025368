import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpaceDto } from 'src/app/data/objects/space.dto';
import { NavigationService } from '../navigation.service';
import { TreeItem } from '../tree-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateSpaceDialogComponent } from 'src/app/share/components/create-space-dialog/create-space-dialog.component';
import { UtilService } from 'src/app/share/utils/util.service';
import { BasicsRepository } from 'src/app/data/repository/basics.repository';
import { EmployeeDto } from 'src/app/data/objects/employee.dto';
import { PermissionDialogComponent } from 'src/app/share/components/permission-dialog/permission-dialog.component';
import { SheetRepository } from 'src/app/data/repository/sheet.repository';
import { NavSharesService } from '../nav-shares.service';
import { SpaceChangeListener, UrlEvents } from 'src/app/events/url.events';
import { Subscription } from 'rxjs';
import { log } from '../../share/utils/logger';
import { MarketplaceDialogComponent } from '../../marketplace/marketplace-dialog/marketplace-dialog.component';
import { environment } from '../../../environments/environment';
import { AllSpacesDialogComponent } from '../../space/all-spaces-dialog/all-spaces-dialog.component';
import { ChangeSpaceListener, MarketplaceEvents } from '../../events/marketplace.events';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '../../data/response/base.response';
import {
  ExportTemplateDialogComponent
} from "../../marketplace/export-template-dialog/export-template-dialog.component";
import {ModalService} from "../../modal/modal.service";
import {SheetDto} from "../../data/objects/Sheet.dto";

@Component({
  selector: 'app-nav-space',
  templateUrl: './nav-space.component.html',
  styleUrls: ['./nav-space.component.scss'],
})
export class NavSpaceComponent
  implements OnInit, SpaceChangeListener, ChangeSpaceListener, OnDestroy
{
  @Input() showTitles: boolean = true;

  indentPx = 12;

  protected readonly environment = environment;
  domain: string | null = localStorage.getItem('domain');

  spaces: SpaceDto[];
  currentSpace: SpaceDto;
  isDefaultSpace: boolean = false;
  defaultSpace: SpaceDto;

  currentDeleting: boolean = false;
  currentRenaming: boolean = false;

  isTreeLoaded:boolean  =false;

  myEmplyee: EmployeeDto;

  tree: TreeItem;
  itemCount: number;
  createExpanded: boolean = false;
  private subscribes = new Subscription();
  activeSheetId: number;
  dir: string = 'ltr';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navService: NavigationService,
    private navSharesService: NavSharesService,
    private utils: UtilService,
    private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private modal: NgbModal,
    private events: UrlEvents,
    private dialogService: ModalService,
    private toastr: ToastrService,
    private marketplaceEvent: MarketplaceEvents,
  ) {
    this.events.onUrlChange(this);
    this.events.subscribeChangeSpace(this);
    this.marketplaceEvent.subscribeSpaceChanged(this);
  }

  async onSpaceChanged(from: string, to: string) {
    if (!to) return;
    this.navService.setCurrentSpaceId(to);
    await this.load('on space changed');
  }

  onUrlChange(url: string, params: any) {}

  onNewItemsAdd() {}

  async ngOnInit() {
    let params = this.navService.getUrlParams();
    if (params['scope'] != 'space') return;

    if (!params['space_id']) {
      await this.navService.goToDefaultSpace();
    } else {
      this.navService.setCurrentSpaceId(params['space_id']);
      await this.load('on init');
    }
  }

  async load(state) {
    this.isTreeLoaded = false;
    let params = this.navService.getUrlParams();
    if (params['sheet_id']) {
      this.activeSheetId = Number(params['sheet_id']);
    }
    this.spaces = await this.navService.getAllSpaces();
    for (let element of this.spaces) {
      if (element.clientData.isHome) {
        this.defaultSpace = element;
      }
      if (element.id == this.navService.currentSpaceId) {
        this.currentSpace = { ...element };
        this.isDefaultSpace = element.clientData.isHome == true;
      }
    }
    if (this.currentSpace == undefined) {
      await this.navService.goToDefaultSpace();
      return await this.load('on current is null');
    }
    this.itemCount = this.currentSpace.folders.length + this.currentSpace.items.length;
    this.myEmplyee = await this.sheetRepo.getMyEmployee();
    for (let folder of this.currentSpace.folders) {
      if (folder.parentId == null) {
        let role = await this.utils.myAccessRole(folder.permission, this.myEmplyee);
        this.tree = {
          colapsed: false,
          editable: true,
          children: [],
          icon: '',
          id: folder.id,
          itemId:folder.id,
          parentId:0,
          name: 'root',
          type: 'root',
          level: 0,
          indentPx: 0,
          permission: folder.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        };
        this.itemCount--;
      }
    }
    if (this.currentSpace.clientData.isHome) {
      let sharesTree = await this.navSharesService.getSharesTree(this.activeSheetId);
      this.tree.children.push(sharesTree);
    }
    await this.addChildren(this.tree);
    this.isTreeLoaded = true;
  }

  showPermissionDialog() {
    const lang = localStorage.getItem('lang');
    let dialog = this.modal.open(PermissionDialogComponent);
    let instance = dialog.componentInstance;
    instance.title = `${lang == 'fa' ? 'دسترسی برای' : 'Permissions For'} ${this.currentSpace.name}`;
    instance.permission = this.currentSpace.permission;
  }

  async deleteCurrentSpace() {
    this.currentDeleting = false;
    await this.sheetRepo.deleteSpace(this.currentSpace.id);
    this.navService.goToDefaultSpace();
  }

  changeCurrentSpace(spaceId) {
    this.navService.goToSpace(spaceId);
    // this.load('change');
  }

  async addChildren(item: TreeItem) {
    if (this.itemCount == 0) return;
    for (let i = 0; i < this.currentSpace.folders.length; i++) {
      let search = this.currentSpace.folders[i];
      if (item.id == search.parentId) {
        let role = await this.utils.myAccessRole(search.permission, this.myEmplyee);
        let newItem: TreeItem = {
          colapsed: true,
          editable: !search.isDefault,
          children: [],
          icon: search.icon || 'bi-folder',
          id: search.id,
          parentId:item.id,
          itemId:search.id,
          name: search.name,
          type: 'folder',
          level: item.level + 1,
          indentPx: (item.level + 1) * this.indentPx,
          permission: search.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        };
        item.children.push(newItem);
        this.itemCount--;
        await this.addChildren(newItem);
        item.colapsed = newItem.colapsed;
      }
    }
    for (let i = 0; i < this.currentSpace.items.length; i++) {
      let search = this.currentSpace.items[i];
      if (item.id == search.folderId) {
        if (search.type == 'SHEET' || search.type == 'PAGE') {
          let role = await this.utils.myAccessRole(search.permission, this.myEmplyee);
          let newItem: TreeItem = {
            colapsed: false,
            editable: true,
            children: [],
            icon: search.icon,
            id: search.refId,
            itemId:search.id,
            name: search.name,
            parentId:item.id,
            type: search.type == 'SHEET' ? 'sheet' : 'page',
            level: item.level + 1,
            indentPx: (item.level + 1) * this.indentPx,
            permission: search.permission,
            myRole: role,
            canDelete: this.utils.canDelete(role),
            canEdit: this.utils.canEdit(role),
            canShare: this.utils.canShare(role),
          };
          item.children.push(newItem);
          if (newItem.id == this.activeSheetId) {
            item.colapsed = false;
          }
          this.itemCount--;
        } else {
          this.itemCount--;
        }
      }
    }
  }

  async onCreateFolderClick() {
    let response = await this.navService.createFolder(this.tree.id);
    if (typeof response === 'string') {
      // error
    } else {
      this.tree.colapsed = false;
      if (response != undefined) {
        let role = await this.utils.myAccessRole(
          response.permission,
          await this.sheetRepo.getMyEmployee(),
        );
        this.tree.children.push({
          children: [],
          editable: true,
          colapsed: true,
          icon: 'bi-folder',
          id: response.id,
          itemId:response.id,
          parentId:this.tree.parentId,
          level: this.tree.level + 1,
          indentPx: (this.tree.level + 1) * this.indentPx,
          name: 'new folder',
          type: 'folder',
          renaming: true,
          permission: response.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        });
      }
    }
  }

  async onCreateSheetClick() {
    let response = await this.navService.createSheet(this.tree.id, 'GENERAL');
    if (!response) {
      // error
      this.toastr.error();
    } else {
      this.tree.colapsed = false;
      let role = await this.utils.myAccessRole(this.currentSpace.permission, this.myEmplyee);
      this.tree.children.push({
        children: [],
        editable: true,
        colapsed: false,
        icon: response.icon,
        id: response.id,
        parentId:this.tree.id,
        itemId:response.id,// fixme must be itemId
        level: this.tree.level + 1,
        indentPx: (this.tree.level + 1) * this.indentPx,
        name: response.name,
        type: 'sheet',
        renaming: true,
        permission: this.currentSpace.permission,
        myRole: role,
        canDelete: this.utils.canDelete(role),
        canEdit: this.utils.canEdit(role),
        canShare: this.utils.canShare(role),
      });
      this.tree = { ...this.tree };
      this.navService.goToSheet(this.currentSpace.id, response.id);
    }
  }

  async onCreateTaskSheetClick() {
    let response = await this.navService.createSheet(this.tree.id, 'TASK');
    if (typeof response === 'string') {
      // error
    } else {
      this.tree.colapsed = false;
      if (response != undefined) {
        let role = await this.utils.myAccessRole(this.currentSpace.permission, this.myEmplyee);
        this.tree.children.push({
          children: [],
          editable: true,
          colapsed: true,
          icon: response.icon,
          id: response.id,
          parentId:this.tree.id,
          itemId:response.id,// FIXME must be item id
          level: this.tree.level + 1,
          indentPx: (this.tree.level + 1) * this.indentPx,
          name: response.name,
          type: 'sheet',
          renaming: true,
          permission: this.currentSpace.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        });
      }
    }
  }

  async onCreatePageClick() {
    let response = await this.navService.createPage(this.tree.id);
    if (BaseResponse.isSuccess(response)) {
      let page = response.data;
      let role = await this.utils.myAccessRole(this.currentSpace.permission, this.myEmplyee);
      this.tree.children.push({
        children: [],
        editable: true,
        colapsed: true,
        icon: page.icon,
        id: page.id,
        itemId:page.id,
        parentId:this.tree.id,
        level: this.tree.level + 1,
        indentPx: (this.tree.level + 1) * this.indentPx,
        name: page.name,
        type: 'page',
        renaming: true,
        permission: this.currentSpace.permission,
        myRole: role,
        canDelete: this.utils.canDelete(role),
        canEdit: this.utils.canEdit(role),
        canShare: this.utils.canShare(role),
      });
    } else {
      this.toastr.error(response.error);
    }
  }

  onCreateSpaceClick() {
    this.modal.open(CreateSpaceDialogComponent).result.then(async ({ name, icon }) => {
      let newSpace = await this.navService.createSpace(name, icon);
      if (typeof newSpace == 'string') {
        // error
      } else if (newSpace != undefined) {
        this.navService.goToSpace(newSpace.id);
        this.currentSpace = newSpace;
      }
    });
  }

  async onRename($event: { icon: string; title: string }) {
    this.currentRenaming = false;
    await this.sheetRepo.updateSpace(this.currentSpace.id, $event.title, $event.icon);
  }

  openContextMenu(myDrop) {
    myDrop.open();
    return false;
  }

  importCsvFile(){
    let ref = this.dialogService.showCsvImport(this.tree.id,this.currentSpace.id);
    ref.onClose.subscribe(async(result:SheetDto)=>{
      this.tree.colapsed = false;
      if (result != undefined) {
        let role = await this.utils.myAccessRole(this.currentSpace.permission, this.myEmplyee);
        this.tree.children.push({
          children: [],
          editable: true,
          colapsed: true,
          icon: result.icon,
          id: result.id,
          parentId:this.tree.id,
          itemId:result.id,
          level: this.tree.level + 1,
          indentPx: (this.tree.level + 1) * this.indentPx,
          name: result.name,
          type: 'sheet',
          renaming: false,
          permission: this.currentSpace.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        });
        this.navService.goToSheet(this.currentSpace.id, result.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscribes.unsubscribe();
  }

  openMarketplace() {
    let dialog = this.modal.open(MarketplaceDialogComponent, { size: 'xl' });
    dialog.componentInstance.currentSpace = this.currentSpace;
    dialog.result.then(async (result: { template; spaceId; folderId }) => {
      let space = await this.navService.refreshSpace(this.currentSpace.id);
      if (space) {
        this.currentSpace = space;
        this.load('from marketplace');
      }
    });
  }


  showExportDialog() {
    const lang = localStorage.getItem('lang');
    const dialog = this.modal.open(ExportTemplateDialogComponent, { size: 'xl' });
    const instance = dialog.componentInstance;
    instance.title = `${lang == 'fa' ? 'بروزن ریزی' : 'Export'} ${this.currentSpace.name}`;
    instance.item = {...this.tree,type:'FOLDER'};
  }


  async onSpaceChange(spaceId: number) {
    const spaces = await this.navService.getAllSpaces();
    const find = spaces.find((space) => space.id == spaceId);
    if (find) {
      this.currentSpace = find;
      await this.load('from marketplace');
    }
  }

  onItemMove(item:TreeItem){
    log('nav space move',item);
    this.addTreeItem(this.tree,item);
  }

  addTreeItem(folder:TreeItem,item:TreeItem){
    log('add tree item',folder.itemId,item.parentId);
    if(folder.itemId == item.parentId){
      item.level = folder.level+1;
      item.indentPx = (folder.level + 1) * this.indentPx;
      folder.children.push(item);
    }else{
      if(folder.type =='folder' || folder.type =='root')
        for(let inner of folder.children){
          if(inner.type == 'folder'){
            this.addTreeItem(inner,item);
          }
        }
    }
  }

}
