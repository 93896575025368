<div class="nav-container" [ngClass]="isCollapse ? 'nav-collapsed' : 'nav-expanded'">
  <div class="nav-content">

    <app-nav-space [showTitles]="!isCollapse" [hidden]="focusArea != 'space'"/>
    <app-nav-messaging [showTitles]="!isCollapse" [hidden]="focusArea != 'chat'"/>
    <app-nav-setting [showTitles]="!isCollapse" [hidden]="focusArea != 'setting'"/>
    <atw-nav-profile [showTitles]="!isCollapse" [hidden]="focusArea != 'profile'"/>
    <app-nav-forms [showTitles]="!isCollapse" [hidden]="focusArea != 'forms'"
                   [refresh]="focusArea == 'forms'"/>
  </div>


  <div
    class="nav-anchor {{isRtl?'rtl-nav':'ltr-nav'}}"
    (click)="onToggleCollapseClick()"
    [ngClass]="isCollapse ? 'anchor-collapsed' : 'anchor-expanded'"
  >
    <i class="anchor-left bi {{isRtl?'bi-chevron-double-right':'bi-chevron-double-left'}}"></i>
    <span class="anchor-pillar"></span>
    <i class="anchor-right bi {{isRtl?'bi-chevron-double-left':'bi-chevron-double-right'}}"></i>
  </div>
</div>
