@if (preferences) {

  <div
    class="dock dock-{{direction}} dock-{{preferences.dock || 'start'}}">
    <atw-image
      (click)="goToHome()"
      type="atwork"
      class="dock-item"
      style="width:36px;height:36px;min-height:36px;margin-top:6px;cursor:pointer;"
      [style.margin-top]="preferences.dock=='bottom' ?0:'6px'"
      [style.margin-inline-start]="preferences.dock!='bottom' ?0:'6px'"
    />

    <p-divider [layout]="preferences.dock =='bottom'?'vertical':'horizontal'"/>

    <div cdkDropList [cdkDropListOrientation]="preferences.dock=='bottom'?'horizontal':'vertical'"
         [cdkDropListLockAxis]="preferences.dock=='bottom'?'x':'y'"
         id="tour_workspaces"
         [cdkDropListData]="items" (cdkDropListDropped)="changeSort($event)"
         class="all-spaces-holder">
          <span placement="end" container="body" [ngbTooltip]="'workspaces'|translate"
                (click)="onLaunchpadClick();$event.stopPropagation()"
                class="dock-item cursor-pointer  border-radius opacity-50 opacity-100-hover mb-2">
              <img src="assets/media/svg/app_launch.svg" width="24" height="24">
            </span>
      @for (item of items; track $index) {

        @if(item.obj){

        <icon cdkDrag [icon]="item.obj.icon" size="xlarge"
              [class.dock-item-active]="item.id == currentItem"
              [routerLink]="item.type=='space' ? '/panel/space/'+item.id+'/home' : '/panel/app/'+item.obj['path']"
              class="dock-item cursor-pointer"
              [ngbTooltip]="item.obj.name" container="body"
        />
        }

      }
      @if (tempItem) {

        <p-divider [layout]="preferences.dock =='bottom'?'vertical':'horizontal'"/>


        <icon [icon]="tempItem.obj.icon" size="xlarge"
              [class.dock-item-active]="tempItem.id == currentItem"
              routerLink="/panel/space/{{tempItem.id}}/home"
              class="dock-item cursor-pointer"
              [ngbTooltip]="tempItem.obj.name" container="body"
        />
      }

    </div>

    <p-divider [layout]="preferences.dock =='bottom'?'vertical':'horizontal'"/>

    <div class="setting-holder">
      <icon
        icon="bi-chat-dots"
        [badge]="chatBadge"
        [active]="focusArea == 'chat'"
        size="xlarge"
        class="dock-item setting-item"
        (click)="messages()"
        ngbTooltip="{{'INBOX' | translate }}"
        container="body"
        placement="auto"
        routerLink="/panel/chat"
        routerLinkActive="orange"
        [ngClass]="focusArea == 'chat' ? 'dock-item-active' : ''"
      />

<!--      <icon-->
<!--        icon="bi-ui-checks"-->
<!--        [active]="focusArea == 'forms'"-->
<!--        size="xlarge"-->
<!--        class="dock-item setting-item"-->
<!--        ngbTooltip="{{'FORMS' | translate}}"-->
<!--        container="body"-->
<!--        placement="auto"-->
<!--        (click)="forms()"-->
<!--        [ngClass]="focusArea == 'forms' ? 'dock-item-active' : ''"-->
<!--      />-->



      <icon
        *ngIf="isOwner"
        icon="bi-gear"
        [active]="focusArea == 'setting'"
        size="xlarge"
        class="dock-item setting-item"
        (click)="administration()"
        ngbTooltip="{{'ADMINISTRATION' | translate }}"
        container="body"
        placement="auto"
        [ngClass]="focusArea == 'setting' ? 'dock-item-active' : ''"
      />
    </div>

  </div>
}
