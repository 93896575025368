import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TreeItem } from '../tree-item';
import { NavigationService } from '../navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionDialogComponent } from 'src/app/share/components/permission-dialog/permission-dialog.component';
import { UtilService } from 'src/app/share/utils/util.service';
import { EmployeeDto } from 'src/app/data/objects/employee.dto';
import { SheetRepository } from 'src/app/data/repository/sheet.repository';
import { RxjsService } from '../../share/services/rxjs/rxjs.service';
import { environment } from '../../../environments/environment';
import { ExportTemplateDialogComponent } from '../../marketplace/export-template-dialog/export-template-dialog.component';
import { Router } from '@angular/router';
import { ImportFilesComponent } from '../../import-files/import-files.component';
import { BaseResponse } from '../../data/response/base.response';
import { ToastrService } from 'ngx-toastr';
import { EntityType } from '../../data/enums/entity-type.enum';
import { Entity } from '../../data/objects/entity';
import {log} from "../../share/utils/logger";
import {ModalService} from "../../modal/modal.service";

@Component({
  selector: 'app-tree-folder',
  templateUrl: './tree-folder.component.html',
  styleUrls: ['./tree-folder.component.scss'],
})
export class TreeFolderComponent implements OnChanges, OnInit {
  indentPx = 12;
  protected readonly environment = environment;

  @Input() currentItem: number;
  @Input() folder: TreeItem;
  @Input() isColapsed: boolean;
  @Input() currentSpaceId: number;
  @Input() showTitles: boolean = true;
  @Output() onDelete = new EventEmitter<TreeItem>();
  @Output() onMove = new EventEmitter<TreeItem>();
  myEmployee: EmployeeDto;

  clickInsideFlag: boolean = false;
  dir: string;
  domain: string | null = localStorage.getItem('domain');

  constructor(
    private navService: NavigationService,
    private utils: UtilService,
    private sheetRepo: SheetRepository,
    private modal: NgbModal,
    private rxjs: RxjsService,
    private router: Router,
    private dialogService:ModalService,
    private toastr: ToastrService,
  ) {}

  async ngOnInit() {
    this.myEmployee = await this.sheetRepo.getMyEmployee();
    this.dir = document.dir;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  openContextMenu(item: TreeItem, myDrop) {
    if (item.canEdit) myDrop.open();
    return false;
  }

  async onRenameSubmit(item: TreeItem) {
    if (item.name.length == 0) return;
    if (!item.renaming) return;
    item.renaming = false;
    if (item.type == 'sheet') {
      let response = await this.navService.updateSheet(
        item.id,
        item.name,
        item.icon,
        item.isHierarchical,
      );
      this.rxjs.broadcastChangeSheetName({ id: item.id, name: item.name, icon: item.icon });
    } else if (item.type == 'page') {
      let response = await this.navService.updatePage(item.id, item.name, item.icon);
    } else {
      let response = await this.navService.updateFolder(item.id, item.name, item.icon);
    }
  }

  async onRenameSubmitDelay(item: TreeItem) {
    setTimeout(() => {
      this.onRenameSubmit(item);
    }, 500);
  }

  async onCreateFolderClick(folder: TreeItem) {
    let response = await this.navService.createFolder(folder.id);
    if (typeof response === 'string') {
      // error
    } else if (response != undefined) {
      let role = await this.utils.myAccessRole(response.permission, this.myEmployee);
      folder.colapsed = false;
      folder.children.push({
        children: [],
        colapsed: true,
        icon: 'bi-folder',
        id: response.id,
        itemId: response.id,
        level: folder.level + 1,
        parentId:this.folder.id,
        indentPx: (folder.level + 1) * this.indentPx,
        name: 'new folder',
        type: 'folder',
        renaming: true,
        permission: response.permission,
        myRole: role,
        canDelete: this.utils.canDelete(role),
        canEdit: this.utils.canEdit(role),
        canShare: this.utils.canShare(role),
        isHierarchical: false,
      });
    }
  }

  async onCreateSheetClick(folder: TreeItem) {
    // FIXME: get permission from sheet
    let response = await this.navService.createSheet(folder.id, 'GENERAL');

    if (typeof response === 'string') {
      // error
    } else {
      folder.colapsed = false;
      if (response != undefined) {
        let role = await this.utils.myAccessRole(folder.permission, this.myEmployee);
        folder.children.push({
          children: [],
          colapsed: true,
          parentId:this.folder.id,
          icon: response.icon,
          id: response.id,
          itemId: response.id,
          level: folder.level + 1,
          indentPx: (folder.level + 1) * this.indentPx,
          name: response.name,
          type: 'sheet',
          renaming: true,
          permission: folder.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
          isHierarchical: response.isHierarchical,
        });
        this.navService.goToSheet(this.currentSpaceId, response.id);
      }
    }
  }

  async onCreateTaskSheetClick(folder: TreeItem) {
    let response = await this.navService.createSheet(folder.id, 'TASK');

    if (typeof response === 'string') {
      // error
    } else {
      folder.colapsed = false;
      if (response != undefined) {
        let role = await this.utils.myAccessRole(folder.permission, this.myEmployee);
        folder.children.push({
          children: [],
          colapsed: true,
          icon: response.icon,
          id: response.id,
          itemId: response.id,
          parentId:this.folder.id,
          level: folder.level + 1,
          indentPx: (folder.level + 1) * this.indentPx,
          name: response.name,
          type: 'sheet',
          renaming: true,
          permission: folder.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
          isHierarchical: false,
        });
      }
    }
  }

  importCsvFile(folder:TreeItem){
    let ref = this.dialogService.showCsvImport(this.folder.id,this.currentSpaceId);
    ref.onClose.subscribe(async(result)=>{
      folder.colapsed = false;
      if (result != undefined) {
        let role = await this.utils.myAccessRole(folder.permission, this.myEmployee);
        folder.children.push({
          children: [],
          editable: true,
          colapsed: true,
          icon: result.icon,
          id: result.id,
          parentId:this.folder.id,
          itemId: result.id,
          level: folder.level + 1,
          indentPx: (folder.level + 1) * this.indentPx,
          name: result.name,
          type: 'sheet',
          renaming: false,
          permission: folder.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        });
        this.navService.goToSheet(this.currentSpaceId, result.id);
      }
    });
  }

  onImportCsvClick(folder: TreeItem) {
    let spaceId = this.currentSpaceId;
    let folderId = folder.id;
    let modalRef = this.modal.open(ImportFilesComponent, {
      animation: true,
      size: 'md',
    });
    modalRef.componentInstance.folderSpace = {
      spaceId: spaceId,
      folderId: folderId,
    };
    modalRef.result.then(async (result) => {
      folder.colapsed = false;
      if (result != undefined) {
        let role = await this.utils.myAccessRole(folder.permission, this.myEmployee);
        folder.children.push({
          children: [],
          editable: true,
          colapsed: true,
          icon: result.icon,
          id: result.id,
          parentId:this.folder.id,
          itemId: result.id,
          level: folder.level + 1,
          indentPx: (folder.level + 1) * this.indentPx,
          name: result.name,
          type: 'sheet',
          renaming: false,
          permission: folder.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        });
        this.navService.goToSheet(spaceId, result.id);
      }
    });
  }

  async deleteItem(item: TreeItem, item_index) {
    if (item.type == 'sheet') {
      let response = await this.navService.deleteSheet(this.currentSpaceId, item.id);
      if (!BaseResponse.isSuccess(response)) {
        this.toastr.error(response.error);
        return;
      }
    } else if (item.type == 'folder') {
      let response = await this.navService.deleteFolder(this.currentSpaceId, item.id);
      if (!BaseResponse.isSuccess(response)) {
        this.toastr.error(response.error);
        return;
      }
    } else if (item.type == 'page') {
      let response = await this.navService.deletePage(this.currentSpaceId, item.id);
      if (!BaseResponse.isSuccess(response)) {
        this.toastr.error(response.error);
        return;
      }
    }
    this.folder.children.splice(item_index, 1);
    let params = this.navService.getUrlParams();
    if (params['sheet_id'] == item.id)
      this.router.navigate([`/panel/space/${this.currentSpaceId}`]).then();
  }

  showPermissionDialog(item: TreeItem) {
    const lang = localStorage.getItem('lang');
    let dialog = this.modal.open(PermissionDialogComponent);
    let instance = dialog.componentInstance;
    instance.title = `${lang == 'fa' ? 'دسترسی برای' : 'Permissions For'} ${item.name}`;
    instance.permission = item.permission;
    instance.sheetId = item.id;
  }

  showExportDialog(item: TreeItem) {
    const lang = localStorage.getItem('lang');
    const dialog = this.modal.open(ExportTemplateDialogComponent, { size: 'xl' });
    const instance = dialog.componentInstance;
    instance.title = `${lang == 'fa' ? 'بروزن ریزی' : 'Export'} ${item.name}`;
    instance.item = item;
  }

  openDropdown(event: MouseEvent, dropdown) {
    dropdown.open();
    event.stopPropagation();
  }

  goToItem(item: TreeItem) {
    this.currentItem = Number(item.id);
    this.navService.goToSheet(this.currentSpaceId, item.id);
  }

  async onCreatePageClick(item: TreeItem) {
    let response = await this.navService.createPage(item.id);
    if (BaseResponse.isSuccess(response)) {
      let page = response.data;
      let role = await this.utils.myAccessRole(item.permission, this.myEmployee);
      item.children.push({
        children: [],
        editable: true,
        colapsed: true,
        icon: page.icon,
        id: page.id,
        parentId:this.folder.id,
        itemId: page.id,
        level: item.level + 1,
        indentPx: (item.level + 1) * this.indentPx,
        name: page.name,
        type: 'page',
        renaming: true,
        permission: item.permission,
        myRole: role,
        canDelete: this.utils.canDelete(role),
        canEdit: this.utils.canEdit(role),
        canShare: this.utils.canShare(role),
      });
    } else {
      this.toastr.error(response.error);
    }
  }

  protected readonly EntityType = EntityType;

  async moveItem(index: number, item: TreeItem, $event: Entity) {
    if (this.folder.id == $event.itemId) {
      return;
    }
    let response = await this.sheetRepo.moveItem(item.itemId, $event.spaceId, $event.itemId);
    if (BaseResponse.isSuccess(response)) {
      log('index',index);
      item.parentId = $event.itemId;
      this.folder.children.splice(index, 1);
      this.onMove.emit(item);
    }
  }

  onItemMove($event:TreeItem){
    this.onMove.emit($event);
  }
}
