import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { SpaceDto } from '../../data/objects/space.dto';
import { BasicsRepository } from '../../data/repository/basics.repository';
import { Title } from '@angular/platform-browser';
import { SheetRepository } from '../../data/repository/sheet.repository';
import { PinItem, PreferencesDto } from '../../data/objects/user.dtos';
import { UrlUtil } from '../../share/utils/url.util';
import { Router } from '@angular/router';
import { EmployeeDto } from 'src/app/data/objects/employee.dto';
import { Subscription } from 'rxjs';
import { UrlChangeListener, UrlEvents } from '../../events/url.events';
import { PreferencesEvents, PreferencesListener } from '../../events/preferences.events';
import {
  NotificationEvents,
  NotificationListener,
  UnseenCountListener,
} from '../../events/notification.events';
import { NotificationDto } from '../../data/objects/notification.dto';
import { ModalService } from 'src/app/modal/modal.service';
import { AppDto } from 'src/app/data/objects/app.dto';
import { AppRepository } from '../../data/repository/app.repository';
import { log } from 'src/app/share/utils/logger';

@Component({
  selector: 'atw-dock',
  templateUrl: './dock.component.html',
  styleUrl: './dock.component.scss',
})
export class DockComponent
  implements
    OnInit,
    UrlChangeListener,
    NotificationListener,
    UnseenCountListener,
    PreferencesListener
{
  focusArea: 'space' | 'setting' | 'chat' | 'forms' | 'profile' | 'app' | string = 'space';
  // pinnedSpaces: SpaceDto[] = [];
  items: DockItem[] = [];
  tempItem: DockItem | undefined;
  // tempSpace: SpaceDto | undefined;
  currentItem: string | number |undefined;
  chatBadge: number = 0;
  isOwner = false;
  isRtl: boolean;

  spaces: SpaceDto[]=[];
  apps: AppDto[] = [];
  preferences: PreferencesDto;
  employee: EmployeeDto;
  subscribes = new Subscription();

  direction = document.dir;

  constructor(
    private navService: NavigationService,
    private modal: NgbModal,
    private modalService: ModalService,
    private baseRepo: BasicsRepository,
    private appRepo: AppRepository,
    private urlUtils: UrlUtil,
    private router: Router,
    private urlEvents: UrlEvents,
    private titleService: Title,
    private prefsEvents: PreferencesEvents,
    private notifEvents: NotificationEvents,
    private sheetRepo: SheetRepository,
  ) {
    this.subscribes.add(this.urlEvents.onUrlChange(this));
    this.urlEvents.subscribeChangeSpace(this);
    this.subscribes.add(this.notifEvents.subscribeNotification(this));
    this.subscribes.add(this.notifEvents.subscribeUnseenCount(this));
    this.subscribes.add(this.prefsEvents.subscribePreferences(this));
  }

  async ngOnInit() {
    this.init();
  }

  async init() {
    this.isOwner = await this.baseRepo.amIOwner();
    this.isRtl = this.baseRepo.isRtl();
    this.spaces = await this.sheetRepo.getSpaces();
    this.apps = await this.appRepo.getApps();
    this.preferences = this.baseRepo.getPreferences();
    this.initFocusArea();
    this.initItems();
    this.employee = await this.navService.getMyEmployee();
    let notifs = await this.baseRepo.getUnseenNotifications();
    if (notifs && notifs.length > 0) {
      this.chatBadge = notifs.length;
    }
  }

  initItems() {
    this.items = (this.preferences.pins || []).map((item) => {
      let obj;
      if (item.type == 'space') obj = this.spaces.find((space) => space.id == item.id);
      if (item.type == 'app') obj = this.apps.find((app) => app.id == item.id);
      return { ...item, obj };
    });
  }

  async onSpaceChanged(from: string, to: string) {
    if (!to) return;
    this.currentItem = to;
    if (!this.spaces) {
      this.spaces = await this.sheetRepo.getSpaces();
    }
    let space = this.spaces.find((space) => `${space.id}` == to);
    if (space && !space?.clientData.pinned) {
      this.tempItem = { obj: space, id: space.id, type: 'space' };
    }
  }

  onPreferencesChanged(prefs: PreferencesDto): void {
    this.preferences = prefs;
    this.initItems();
  }

  onUnseenNotifCountChange(count: number): void {
    this.chatBadge = count;
  }

  onNewNotification(notif: NotificationDto): void {
    this.chatBadge++;
  }

  ngOnDestroy(): void {
    this.subscribes.unsubscribe();
  }

  onUrlChange(url: string) {
    this.initFocusArea();
  }

  async setCurrentTempSpace() {
    if (this.currentItem) {
      if (!this.spaces) this.spaces = await this.sheetRepo.getSpaces();
      let space = this.spaces.find((space) => space.id == this.currentItem);
      if (space && !space?.clientData.pinned) {
        this.tempItem = { obj: space, type: 'space', id: space.id };
      }
    }
  }

  initFocusArea() {
    // this.focusArea = this.router.url.split('/')[2];
    this.focusArea = this.urlUtils.getParams()['scope'];
    log('focus area',this.focusArea);
    if (this.focusArea == 'space') {

    }

    if (this.focusArea == 'setting') {
      this.currentItem = undefined;
      this.titleService.setTitle('Atwork | Administration');
    } else if (this.focusArea == 'chat') {
      this.currentItem = undefined;

      this.titleService.setTitle('Atwork | Messages');
    } else if (this.focusArea == 'forms') {
      this.currentItem = undefined;

      this.titleService.setTitle('Atwork | Forms');
    } else if (this.focusArea == 'space') {
      this.currentItem = this.urlUtils.getSpaceId();
      this.setCurrentTempSpace();
    } else if (this.focusArea == 'profile') {
      this.currentItem = undefined;
      this.titleService.setTitle('Atwork | Profile');
    } else if (this.focusArea == 'app') {
      let path=this.urlUtils.getAppPath();
      let app = this.apps.find(app=>app.path==path);
      if(app)this.currentItem = app.id;
    }
  }

  goToHome() {
    this.focusArea = 'space';
    this.navService.goToDefaultSpace();
  }

  onLaunchpadClick() {
    this.modalService.showLaunchpadDialog();
  }

  changeSort($event: CdkDragDrop<DockItem[]>) {
    transferArrayItem(
      $event.previousContainer.data,
      $event.container.data,
      $event.previousIndex,
      $event.currentIndex,
    );
    let items = this.items.reduce((acc, item) => {
      acc.push({ id: item.id, type: item.type });
      return acc;
    }, [] as PinItem[]);
    this.baseRepo.sortPins(items);
  }

  messages() {
    this.focusArea = 'chat';
    this.titleService.setTitle('Atwork | Messages');
    this.navService.goToChat();
  }

  profile() {
    this.focusArea = 'profile';
    this.titleService.setTitle('Atwork | Profile');
    this.navService.goToMyProfile();
  }

  administration() {
    this.focusArea = 'setting';
    this.titleService.setTitle('Atwork | Administration');
    this.navService.goToOrgInfo();
  }

  forms() {
    this.focusArea = 'forms';
    this.titleService.setTitle('Atwork | Forms');
    this.navService.goToForms();
  }
}

export interface DockItem extends PinItem {
  obj: SpaceDto | AppDto;
}
